import * as _ from 'lodash';
import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import { Regulation } from '../interfaces';
import { Layout, RegulationBoxes, StateRegulationLinks } from '../components/common';

interface Props {
  data: {
    regulation: Omit<Regulation, 'country_name'>;
  };
}

const NotReady = (props: Props) => {
  const {
    data: { regulation },
  } = props;
  const state = regulation.state_name.toUpperCase().split(' ').map(_.capitalize).join(' ');

  return (
    <Layout>
      <Helmet>
        <title>{_.capitalize(regulation.state_name.toLowerCase())} Laws Summarized</title>
        <meta
          name="description"
          content={`${state} rent laws and regulations summarized in plain english. Stop searching forever for what you is afforded to you legally as a tenant.`}
        />
      </Helmet>
      <section className="hero">
        <div className="hero-body">
          <h1 className="title has-text-centered is-capitalized">{state} Laws Summarized</h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <p className="is-size-4 has-text-weight-bold has-text-centered has-text-justified-mobile mb-6">
            We haven&apos;t summarized the rules for {state} yet.
          </p>
          <p className="is-size-4 has-text-weight-bold has-text-centered has-text-justified-mobile">
            {' '}
            If you need info immediately, check in with the folks at{' '}
            <a href="https://reddit.com/r/tenant" title="reddit community to help renters">
              /r/Tenant
            </a>{' '}
            or contact one of the founders at{' '}
            <a href="https://padfever.com/" title="padfever is the missing toolkit for renters">
              Padfever
            </a>
            .
          </p>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h2 className="title is-2 has-text-centered has-mb-1">Find laws by state</h2>
          <StateRegulationLinks />
        </div>
      </section>
    </Layout>
  );
};

const Ready = (props: Props) => {
  const {
    data: { regulation },
  } = props;
  const state = regulation.state_name.toUpperCase().split(' ').map(_.capitalize).join(' ');

  return (
    <Layout>
      <Helmet>
        <title>{_.capitalize(regulation.state_name.toLowerCase())} Laws Summarized</title>
        <meta
          name="description"
          content={`${state} rent laws and regulations summarized in plain english. Stop searching forever for what you is afforded to you legally as a tenant.`}
        />
      </Helmet>
      <section className="hero is-black">
        <div className="hero-body">
          <h1 className="title has-text-centered is-capitalized">{state} Laws Summarized</h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <RegulationBoxes regulation={regulation} />
        </div>
      </section>
    </Layout>
  );
};

const RegulationsTemplate = (props: Props) => {
  const {
    data: { regulation },
  } = props;

  if (regulation.status === 'AVAILABLE') {
    return <Ready {...props} />;
  } else {
    return <NotReady {...props} />;
  }
};

export default RegulationsTemplate;

export const query = graphql`
  query($id: String!) {
    regulation: rentControlRegulation(id: { eq: $id }) {
      status
      country_code
      state_code
      state_name
      allowed {
        label
        tags
      }
      disallowed {
        label
        tags
      }
      obligations {
        label
        tags
      }
    }
  }
`;
